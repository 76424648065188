<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>หลักสูตร</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-btn color="primary" class="mr-1" @click="handleClickAdd"
                >เพิ่ม</v-btn
              >
              <v-btn
                color="success"
                @click="handleSync"
                :loading="loading"
                :disabled="loading"
                >ซิงค์ข้อมูล LU</v-btn
              >
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="หลักสูตร"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :loading="loading"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <!-- <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>-->
                <!-- Action menu -->
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog New Course-->
    <v-dialog v-model="dialogFormCourse" max-width="150vh" persistent>
      <v-card>
        <v-toolbar card color="primary"
          ><v-btn icon dark @click="dialogFormCourse = false">
            <v-icon>mdi-close</v-icon> </v-btn
          ><v-toolbar-title>สร้างหลักสูตร</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <form>
            <input v-if="editMode" type="hidden" v-model="formModel.id" />
            <v-text-field
              v-model="formModel.title"
              :counter="255"
              label="ชื่อหลักสูตร"
              required
            ></v-text-field>
            <v-select
              :items="coursesLevel"
              label="ระดับ"
              v-model="formModel.level"
            ></v-select>
            <v-textarea
              v-model="formModel.objective"
              label="วัตถุประสงค์"
              auto-grow
              rows="4"
            ></v-textarea>
            <!-- CourseLevel -->
            <v-select
              :items="location.items"
              :item-text="'title'"
              :item-value="'id'"
              v-model="location.selected"
              label="สถานที่"
              return-object
              multiple
            ></v-select>
            <v-text-field
              v-model="formModel.period"
              label="ระยะเวลา"
            ></v-text-field>
            <v-textarea
              v-model="formModel.condition"
              auto-grow
              rows="4"
              label="เงื่อนไข"
            >
            </v-textarea>
            <v-textarea
              v-model="formModel.description"
              label="รายละเอียด"
              auto-grow
              rows="4"
            >
            </v-textarea>
          </form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="handleSubmit"
            >บันทึก</v-btn
          >
          <v-btn @click="dialogFormCourse = false">ปิด</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog Detail -->
    <v-dialog v-model="dialog" max-width="150vh" persistent scrollable>
      <v-card>
        <v-toolbar card color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>รายละเอียดหลักสูตร</v-toolbar-title>
          <v-progress-circular
            class="ml-1"
            v-if="dialogActivityLoading"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-toolbar>
        <v-card-text>
          <v-row v-if="dialogActivityLoading == false">
            <v-col>
              <h3>รหัส</h3>
              <p>{{ formModel.code }}</p>
              <h3>ชื่อหลักสูตร</h3>
              <p>{{ formModel.title }}</p>
              <h3>ระดับ</h3>
              <p>{{ formModel.level }}</p>
              <h3>สถานที่</h3>
              <p>{{ formModel.location }}</p>
              <h3>กิจกรรม</h3>
              <div>
                <ul v-for="activity in activitiesList" :key="activity.id">
                  <li v-if="activity.selected">{{ activity.title }}</li>
                </ul>
              </div>
              <h3>ระยะเวลา</h3>
              <p>{{ formModel.period }}</p>
              <h3>วัตถุประสงค์</h3>
              <p>{{ formModel.objective }}</p>
              <h3>เงื่อนไข</h3>
              <p>{{ formModel.condition }}</p>
              <h3>รายละเอียด</h3>
              <p>{{ formModel.description }}</p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog activity management -->
    <v-dialog v-model="dialogActivity" max-width="100vh" persistent scrollable>
      <v-card>
        <v-toolbar color="primary" card>
          <v-btn icon dark @click="dialogActivity = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>จัดการกิจกรรม</v-toolbar-title>
          <v-progress-circular
            class="ml-1"
            v-if="dialogActivityLoading"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-toolbar>
        <v-card-text>
          <v-row v-if="dialogActivityLoading == false">
            <div v-for="activity in activitiesList" :key="activity.id">
              <v-checkbox
                class="col-12"
                v-model="activity.selected"
                :label="activity.title"
                hide-details
              ></v-checkbox>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="handleActivitySubmit">
            บันทึก
          </v-btn>
          <v-btn color="secondary" @click="dialogActivity = false">
            ปิด
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Util from '@/util'
import API from '@/api'
import { defaultCatch } from '@/api/fetch'
import {
  Courses,
  getCourse,
  newCourses,
  updateCourses,
  updateActivities,
  CoursesLevel,
  syncData
} from '@/api/course'
import { getActivity, getActivityByCourse } from '@/api/activity'
import { getLocation } from '@/api/location'

export default {
  name: 'CourseList',
  data() {
    return {
      loginUser: null,
      coursesLevel: CoursesLevel,
      location: {
        selected: [],
        items: []
      },
      // locationList: getLocation(),
      courseId: '',
      activitiesList: [],
      editMode: false,
      loading: false,
      dialog: false,
      dialogActivity: false,
      dialogFormCourse: false,
      dialogActivityLoading: false,
      newItemUrl: API.getLuUrl('/course/new'),
      formModel: Courses,
      activities: [],
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'รหัส',
            value: 'code'
          },
          {
            text: 'หลักสูตร',
            value: 'title'
          },
          {
            text: 'ประเภท',
            value: 'level'
          },
          {
            text: 'สถานที่',
            value: 'location'
          },
          {
            text: 'ระยะเวลา',
            value: 'period'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'รายละเอียด',
          icon: 'mdi-eye',
          click: this.handleViewItem
        },
        {
          text: 'กิจกรรม',
          icon: 'mdi-biathlon',
          click: this.handleActivity
        },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        }
      ]
    }
  },
  created() {
    this.loginUser = this.getLoginUser
    this.fetchData()
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getLoginUser'])
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([getCourse('', 100, 0), getActivity(), getLocation()])
        .then(([courses, activities, locations]) => {
          this.complex.items = courses
          this.activitiesList = activities
          this.location.items = locations
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.loading = false
        })

      // getCourse('', 0, 20)
      //   .then((courses) => {
      //     this.complex.items = courses
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
      //   .finally(() => {
      //     this.loading = false
      //   })
      // getActivity().then((activities) => {
      //   this.activitiesList = activities
      // })
      // getLocation().then((locations) => {
      //   this.location.items = locations
      // })
    },
    fetchActivity(courseId) {
      this.dialogActivityLoading = true
      getActivityByCourse(courseId)
        .then((activities) => {
          this.activities = activities
          const activityIds = activities.map((item) => {
            return item.id
          })
          console.log(activityIds)
          this.activitiesList.forEach((element) => {
            if (activityIds.indexOf(element.id) != -1) {
              element.selected = true
            } else {
              element.selected = false
            }
          })
        })
        .catch((error) => {
          console.error(new Error(error))
        })
        .finally(() => {
          this.dialogActivityLoading = false
        })
    },
    handleSync() {
      this.loading = true
      syncData(this.loginUser.lu_token)
        .then((synced) => {
          Util.syncedDialog(this.$alert, synced)
          this.fetchData()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClick(row) {
      this.handleViewItem(row)
    },
    handleClickAdd() {
      this.editMode = false
      this.formModel = Object.assign(Courses)
      this.dialogFormCourse = true
    },
    handleViewItem(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.fetchActivity(row.id)
      this.dialog = true
    },
    handleEditItem(row) {
      this.editMode = true
      this.formModel = Object.assign(row)
      this.dialogFormCourse = true
      // window.open(API.getLuUrl(`/courses/${row.id}`), '_blank')
    },
    handleDeleteItem() {},
    handleSubmit() {
      this.loading = true
      let premiss
      if (this.editMode) {
        //  update Courses
        premiss = updateCourses(this.formModel.id, this.formModel).then(() => {
          this.complex.items = this.complex.items.map((item) => {
            return item.id == this.formModel.id ? this.formModel : item
          })
          // this.complex.items.push(courses)
          this.formModel = Object.assign({}, Courses)
        })
      } else {
        // new Courses
        premiss = newCourses(this.formModel).then((courses) => {
          this.complex.items.unshift(courses)
          this.formModel = Object.assign(Courses)
        })
      }
      // Handle catch , finally
      premiss
        .catch((error) => {
          console.error(new Error(error))
        })
        .finally(() => {
          this.loading = false
          this.dialogFormCourse = false
        })
    },
    // จัดการ activity
    handleActivity(row) {
      this.activities = []
      this.dialogActivity = true
      this.fetchActivity(row.id)
      this.courseId = row.id
    },
    handleActivitySubmit() {
      this.loading = true
      const selectedActity = this.activitiesList
        .filter((item) => {
          return item.selected == true
        })
        .map((item) => item.id)
      console.log(selectedActity)

      updateActivities(this.courseId, selectedActity)
        .then(() => {
          this.dialogActivity = false
          this.activities = []
        })
        .catch(defaultCatch)
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
